<template>
  <div
    style="position: fixed; left: 0; z-index: 200"
    :style="{ [position]: 0 }"
    class="w-100 py-2 position-md-static bg stickablebutton"
    :class="`bg-${bg}${border ? ' border-top' : ''}`"
  >
    <div class="container p-0">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StickableButton',
  props: {
    border: {
      type: Boolean,
      required: false,
      default: true,
    },
    position: {
      type: String,
      required: false,
      default: 'bottom',
    },
    bg: {
      type: String,
      required: false,
      default: 'white',
    },
  },
};
</script>
